import React from "react"

import LegalLayout from "../../views/legal/LegalLayout";
import PrivacyPage from "../../views/legal/PrivacyPage";

const Privacy = ({ location, pageContext: { locale } }) => (
    <LegalLayout location={location} locale={locale}>
        <PrivacyPage />
    </LegalLayout>
);

export default Privacy;
