import React, { Fragment } from "react";

import { withTranslate } from "../../components/translate";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import SEO from "../../components/SEO";
import AlphaList from "../../components/common/AlphaList";
import List from "../../components/common/List";
import Link from "../../components/common/Link";

const ListItem = function ({ title, children }) {
    return (
        <div>
            <b>{title}</b><br/>
            {children}
        </div>
    );
}

const PrivacyPage = withTranslate(function ({ translate }) {
    return (
        <Fragment>
            <SEO
                title={translate("legal.privacy.head.title")}
                description={translate("legal.privacy.head.description")}
                path="/legal/privacy"
            />
            <ScrollToTopOnMount />
            <header className="container py-3 mt-4 mb-5">
                <div className="intro mt-3">
                    <h1>
                        {translate("legal.privacy.title")}
                    </h1>
                    <p className="h2-sub mb-0">
                        {translate("legal.privacy.subtitle")}
                    </p>
                </div>
            </header>
            <div className="container mb-5 pb-5">
                <p>CheekSoft Oy ("noi") rispetta la tua privacy. Offriamo servizi che consentono di condurre un’attività e di creare e gestire in sicurezza documenti ufficiali.</p>
                
                <p>Questa Privacy Policy descrive i tipi di Dati Personali che raccogliamo attraverso i nostri prodotti e servizi ("Servizi") e tramite la nostra presenza online, che include il nostro sito principale all'indirizzo dockington.com (“Sito”). Questa Policy descrive anche come utilizziamo i Dati Personali, con chi li condividiamo, i tuoi diritti e le tue scelte, e come puoi contattarci riguardo alle nostre pratiche sulla privacy. Questa Privacy Policy non è applicabile a siti, prodotti o servizi esterni, anche se collegati ai nostri Servizi o al nostro Sito, e in questi casi ti consigliamo di considerare attentamente le Privacy Policy di tali terze parti.</p>
 
                <h2 id="overview">1. Panoramica</h2>
                <p>Raccogliamo Dati Personali su di te da varie fonti per fornire i nostri Servizi e gestire il nostro Sito. "Tu" potresti essere un visitatore del nostro Sito o un utente di uno o più dei nostri Servizi ("Utente").</p>
                <h2 id="what-we-collect">2. I Dati Personali che raccogliamo</h2>
                <AlphaList
                    className="mb-1"
                    items={[
                        <p>
                            <b>Dati personali che raccogliamo su di te.</b><br/>
                            I Dati Personali sono tutte le informazioni relative a un individuo identificato o identificabile.
                            Quando ti registri per ottenere un account Dockington raccogliamo nome, azienda, indirizzo email e credenziali di accesso all'account.
                            Quando rispondi alle nostre email o ai nostri sondaggi raccogliamo nome, indirizzo email e ogni altra informazione che decidi di includere nella tua email o nelle tue risposte. Se ci contatti per telefono, potremmo raccogliere il numero di telefono che usi per chiamare. Se ci contatti telefonicamente come Utente Dockington, potremmo raccogliere ulteriori informazioni al fine di verificare la tua identità.
                            Se sei un Utente Dockington, dovrai fornirci i tuoi dettagli di contatto, come nome, indirizzo postale, numero di telefono e indirizzo email. Come parte del tuo rapporto commerciale con noi, potremmo anche richiederti informazioni finanziarie e personali su di te e sulla tua azienda, come codice fiscale e partita iva.
                            Inoltre, puoi scegliere di inviarci informazioni tramite altri metodi, tra cui: (i) in risposta al nostro marketing o altre comunicazioni, (ii) attraverso social media o forum online, (iii) attraverso la partecipazione a un'offerta, un programma o una promozione (i) in connessione con un rapporto commerciale effettivo o potenziale con noi. 
                        </p>,
                        <p>
                            <b>Informazioni che raccogliamo automaticamente sul nostro Sito.</b><br/>
                            Il nostro Sito utilizza cookie e altre tecnologie per funzionare in modo efficace. Queste tecnologie raccolgono informazioni anonime sull'utilizzo del nostro Sito, tra cui:
                            <List
                                className="mb-0"
                                simple
                                items={[
                                    "Dati del browser e del dispositivo, come tipo di dispositivo, sistema operativo e tipo di browser, risoluzione dello schermo, nome e versione del sistema operativo, produttore e modello del dispositivo, lingua, plug-in, componenti aggiuntivi e versione linguistica del Sito che stai visitando;",
                                    "Dati di utilizzo, come tempo trascorso sul Sito, pagine visitate, link cliccati, preferenze linguistiche, e le pagine che ti hanno portato o indirizzato al nostro Sito.",
                                ]}
                            />
                        </p>,
                    ]}
                />
                <p>Sul nostro Sito utilizziamo Google Analytics per aiutarci ad analizzare il Tuo utilizzo del nostro Sito e a diagnosticare problemi tecnici. Per saperne di più su Google Analytics e la sua Privacy Policy, visita il <a href="https://policies.google.com/privacy?hl=it&gl=it">sito di Google</a>.</p>
                <p>Per sapere quali cookie utilizza il nostro Sito e come controllare il nostro utilizzo di cookie e analisi di terze parti, consulta la nostra <Link to="/legal/cookies">Cookie Policy</Link>.</p>

                <h2 id="how-we-use">3. Come usiamo i Dati Personali</h2>
                <AlphaList
                    className="mb-1"
                    items={[
                        <ListItem title={"I nostri prodotti e servizi."}>
                            Raccogliamo e utilizziamo i tuoi Dati Personali e altre informazioni fornite al momento della registrazione e durante l'utilizzo dei nostri Servizi, in quanto necessari per fornirti i Servizi.
                        </ListItem>,
                        <ListItem title={"Marketing e comunicazioni relative a eventi."}>
                            Potremmo inviarti comunicazioni di marketing via email relative ai nostri Servizi, invitarti a partecipare a nostri eventi o sondaggi, o comunicare con te per scopi di marketing, sempre in conformità con i requisiti di consenso imposti dalla legge applicabile. Quando raccogliamo i tuoi contatti o quelli della tua azienda durante fiere o altri eventi, potremmo utilizzarli per contattarti in seguito all’evento, inviarti informazioni che hai richiesto sui nostri prodotti e servizi e, con il tuo permesso, includerti nelle nostre campagne informative di marketing.
                        </ListItem>,
                        <ListItem title={"Pubblicità basata sugli interessi."}>
                            <p>Quando visiti il nostro Sito o i nostri servizi online, sia noi che determinate terze parti raccogliamo informazioni sulle tue attività online nel tempo e su diversi siti per fornirti pubblicità su prodotti e servizi personalizzati per i tuoi interessi individuali (questo tipo di pubblicità è chiamata "pubblicità basata sugli interessi").</p>
                            <p>Queste terze parti possono inserire o riconoscere un cookie univoco o un’altra tecnologia nel tuo browser (compreso l'uso di pixel tag). Laddove richiesto dalla legge applicabile, otterremo il tuo consenso prima del trattamento delle tue informazioni ai fini della pubblicità basata sugli interessi.</p>
                            <p>Potresti vedere i nostri annunci su altri siti o app mobili nel caso in cui prendessimo parte a reti pubblicitarie. Le reti pubblicitarie ci consentono di indirizzare i nostri messaggi agli utenti in base a una serie di fattori, tra cui dati demografici, interessi degli utenti e contesto di navigazione (ad esempio, l'ora e la data della visita al nostro Sito, le pagine visualizzate e i link su cui hai cliccato). Questa tecnologia ci aiuta anche a monitorare l'efficacia dei nostri sforzi di marketing e a capire se hai visto uno dei nostri annunci pubblicitari.</p>
                        </ListItem>,
                    ]}
                />

                <h2 id="how-we-disclose">4. Come trattiamo i Dati Personali</h2>
                <p>Non vendiamo, prestiamo o condividiamo Dati Personali con marketer o terze parti non affiliate. Condividiamo i Tuoi Dati Personali con entità fidate, come descritto di seguito.</p>
                <AlphaList
                    className="mb-1"
                    items={[
                        <ListItem title={"Fornitori di servizi."}>
                            Condividiamo Dati Personali con un numero limitato di nostri fornitori di servizi, quali fornitori di servizi di pagamento e di consegna email. Questi fornitori di servizi potrebbero aver bisogno di accedere a Dati Personali per svolgere i loro servizi per nostro conto. Autorizziamo tali fornitori di servizi a utilizzare Dati Personali solo se necessario per eseguire servizi per nostro conto o per soddisfare requisiti legali. Richiediamo a tali fornitori di servizi di impegnarsi contrattualmente a proteggere la sicurezza e la riservatezza dei Dati Personali che elaborano per nostro conto.
                        </ListItem>,
                        <ListItem title={"Conformità e prevenzione danni."}>
                            Condividiamo Dati Personali come riteniamo necessario: (i) per rispettare la legge applicabile; (ii) per far valere i nostri diritti contrattuali; (iii) per proteggere i diritti, la privacy, la sicurezza e la proprietà di CheekSoft Oy, vostra o di altri; e (iv) rispondere alle richieste dei tribunali, delle forze dell'ordine, delle agenzie di regolazione e di altre autorità pubbliche e governative, che possono includere autorità al di fuori del proprio paese di residenza.
                        </ListItem>,
                    ]}
                />

                <h2 id="your-rights">5. I tuoi diritti e le tue scelte</h2>
                <p>I Tuoi diritti in merito al nostro uso e trattamento dei Tuoi Dati Personali sono i seguenti:</p>
                <AlphaList
                    className="mb-1"
                    items={[
                        <ListItem title={"Decidere di non ricevere più nostre comunicazioni elettroniche."}>
                            Se non desideri più ricevere email di marketing da parte nostra, puoi annullare l’iscrizione tramite il link di annullamento dell'iscrizione incluso in tali e-mail. Cercheremo di soddisfare la tua richiesta non appena possibile. Ti preghiamo di notare che se ti rifiuti di ricevere email di marketing da parte nostra, potremmo comunque inviarti importanti messaggi amministrativi necessari per fornirti i nostri Servizi.
                        </ListItem>,
                        <ListItem title={"Vedere o modificare i Dati Personali del Tuo account."}>
                            Se desideri rivedere, correggere o aggiornare i Dati personali che ci hai precedentemente comunicato, puoi farlo accedendo al tuo account Dockington o contattandoci a <a href="mailto:privacy@cheeksoft.com">privacy@cheeksoft.com</a>.
                        </ListItem>,
                        <ListItem title={"I Tuoi diritti di protezione dei dati."}>
                            <p>A seconda della Tua posizione e in base alla legge applicabile, potresti avere i seguenti diritti relativamente ai Tuoi Dati Personali:</p>
                            <List
                                simple
                                items={[
                                    "Il diritto di chiedere conferma del trattamento dei Tuoi Dati Personali e, in caso affermativo, di richiedere una copia di tali Dati Personali;",
                                    "Il diritto di richiedere la rettifica o l’aggiornamento dei Tuoi Dati Personali in quanto inesatti, incompleti o non aggiornati;",
                                    "Il diritto di richiedere la cancellazione dei Tuoi Dati Personali in determinate circostanze previste dalla legge;",
                                    "Il diritto di richiedere la limitazione dell'uso dei Tuoi Dati Personali in determinate circostanze, ad esempio mentre consideriamo un'altra Tua richiesta (inclusa la richiesta di aggiornamento ai Tuoi Dati Personali).",
                                ]}
                            />
                            <p>Nel caso in cui il trattamento dei Tuoi Dati Personali sia basato sul Tuo consenso precedentemente fornito, hai il diritto di revocare il Tuo consenso in qualsiasi momento.</p>
                        </ListItem>,
                        <ListItem title={"Processo per l'esercizio dei diritti di protezione dei dati."}>
                            Per esercitare i Tuoi diritti di protezione dei dati, puoi contattare CheekSoft Oy come descritto nella sezione Contattaci di seguito. Prendiamo seriamente ogni richiesta. Rispetteremo la Tua richiesta nella misura richiesta dalla legge applicabile. Non saremo in grado di rispondere a una richiesta se non avremo più i Tuoi Dati Personali. Se ritieni di non aver ricevuto una risposta soddisfacente da parte nostra, puoi consultare l'autorità per la protezione dei dati del Tuo paese.
                        </ListItem>
                    ]}
                />
                <p>Per la Tua protezione, potremmo aver bisogno di verificare la Tua identità prima di rispondere alla Tua richiesta, come ad esempio verificare che l'indirizzo email da cui invii la richiesta corrisponda al Tuo indirizzo email che abbiamo in archivio. Nel caso in cui il trattamento dei Tuoi Dati Personali non sia più necessario per fornirti i nostri Servizi o il nostro Sito, non conserveremo, acquisiremo o elaboreremo ulteriori informazioni per identificarti al fine di rispondere alla Tua richiesta.</p>

                <h2 id="security">6. Sicurezza e conservazione</h2>
                <p>Ci adoperiamo per garantire un livello di sicurezza adeguato ai rischi associati al trattamento dei Dati Personali. Manteniamo misure organizzative, tecniche e amministrative volte a proteggere i Dati Personali all'interno della nostra organizzazione da accessi non autorizzati, distruzione, perdita, alterazione o uso improprio. I Tuoi Dati Personali sono accessibili solo a un numero limitato di persone facenti parte di CheekSoft Oy che hanno bisogno di accedere alle informazioni per svolgere i loro compiti. Sfortunatamente, nessun sistema di trasmissione o archiviazione dati può essere garantito sicuro al 100%. Se hai motivo di credere che la Tua interazione con noi non sia più sicura (ad esempio, se ritieni che la sicurezza del tuo account sia stata compromessa), ti preghiamo di contattarci immediatamente all'indirizzo <a href="mailto:privacy@cheeksoft.com">privacy@cheeksoft.com</a>.</p>
                <p>Se sei un Utente Dockington, conserviamo i Tuoi Dati Personali finché ti forniamo i nostri Servizi. Conserviamo i Dati Personali dopo la cessazione della fornitura dei Servizi, anche dopo la chiusura dell’account Dockington, per la durata della conservazione dei dati menzionata nei nostri <Link to="/legal/terms">Termini di Servizio</Link>. Continueremo a conservare il Tuo nome e indirizzo email per scopi di marketing, finché non sceglierai di non ricevere più tali comunicazioni.</p>

                <h2 id="updates">7. Aggiornamenti a questa Privacy Policy e comunicazioni</h2>
                <p>Potremmo cambiare questa Privacy Policy di tanto in tanto per riflettere nuovi servizi, modifiche alle nostre pratiche sui Dati Personali o leggi pertinenti. La legenda "Ultimo aggiornamento" nella parte superiore di questa Privacy Policy indica l’ultima volta che questa Privacy Policy è stata aggiornata. Qualsiasi modifica diventa effettiva al momento della pubblicazione della Privacy Policy aggiornata sul Sito. Potremmo fornirti comunicazioni e avvisi relativi alla Privacy Policy o ai Dati Personali raccolti pubblicandoli sul nostro Sito e, se sei un Utente, contattandoti tramite il tuo indirizzo email.</p>

                <h2 id="contact">8. Contattaci</h2>
                <p>In caso di domande o reclami su questa Privacy Policy, ti preghiamo di contattarci per via elettronica all'indirizzo <a href="mailto:privacy@cheeksoft.com">privacy@cheeksoft.com</a> o fisica a:</p>
                <p className="address">
                    <b>CheekSoft Oy</b><br/>
                    Väinämöisenkatu 23 B 31<br/>
                    00100 Helsinki<br/>
                    Finland
                </p>
            </div>
        </Fragment>
    )
});

export default PrivacyPage;
